import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { colors, fonts } from '@styles/ui_palette';
import { rgba } from 'emotion-rgba';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Logout, useChain } from '@utils/interact';
import { Store } from 'react-notifications-component';

// recoil
import { UserAddress, Chain } from '@recoil/auth';

interface IHambuger {
    setIsMenu: any;
}

function Hambuger({ setIsMenu }: IHambuger) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const userAddress = useRecoilValue(UserAddress);
    const chainId = useRecoilValue(Chain);

    const moveLink = (type: string, url:string) => {
        setIsMenu(false);

        if (type === 'out') {
            window.open(url);
            return;
        }

        navigate(url);
    };

    const handleLogout = () => {
        setIsMenu(false);
        Logout(t);
    };

    const CopyNoti = () => {
        setIsMenu(false);

        Store.addNotification({
            title: t('Notification.succeseTitle'),
            message: t('Notification.copyMessage'),
            type: 'default',
            insert: 'top',
            container: 'top-left',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
                duration: 1000,
            },
        });
    };

    useEffect(() => {
        document.body.style.position = 'fixed';
        document.body.style.width = '100%';
        document.body.style.height = '100%';

        return () => {
            document.body.style.position = 'static';
            document.body.style.width = 'inherit';
            document.body.style.height = 'inherit';
        };
    }, []);

    return (
        <HambugerContainer>
            <HambugerBox>
                <HambugerClose>
                    <HambugerTitle>
                        안녕하세요.
                    </HambugerTitle>
                    <CloseBtn
                        src="/img/common/ic_close_btn.svg"
                        onClick={() => setIsMenu(false)}
                    />
                </HambugerClose>
                {userAddress.address !== '' && (
                    <>
                        <UserProfile>
                            <ProfileImageBox onClick={() => moveLink('mypage', `/mypage/${userAddress.address}`)}>
                                <ProfileImage src={userAddress.image_profile ? userAddress.image_profile : '/img/common/img_default_profile.png'} />
                            </ProfileImageBox>
                            <TitleSection onClick={() => moveLink('mypage', `/mypage/${userAddress.address}`)}>
                                <span>Creator</span>
                                {userAddress.user_name}
                            </TitleSection>
                        </UserProfile>
                        <WalletAdddress>
                            <AddressTitle>
                                지갑주소
                            </AddressTitle>
                            <AdddressBox>
                                <Address>{userAddress.address}</Address>
                                <CopyToClipboard
                                    text={userAddress.address}
                                    onCopy={CopyNoti}
                                >
                                    <Copy src="/img/common/ic_clip_copy.svg" />
                                </CopyToClipboard>
                            </AdddressBox>
                        </WalletAdddress>
                        <KlayBalanceBox>
                            <BalanceBox>
                                <CoinIcon src="/img/common/ic_symbol_KLAY.png" />
                                <BalancePrice>
                                    {Number(userAddress.balance).toFixed(4)}
                                    <span>{useChain[chainId].symbol === 'KRW' ? 'KLAY' : useChain[chainId].symbol}</span>
                                </BalancePrice>
                            </BalanceBox>
                            <InfoText>
                                * 반올림 금액으로 실제 금액과 다를 수 있습니다.
                            </InfoText>
                        </KlayBalanceBox>
                    </>
                )}
                {userAddress.address === '' && (
                    <>
                        <LoginBox onClick={() => moveLink('login', '/login')}>
                            <LogInText>로그인</LogInText>
                        </LoginBox>

                        <LoginInfoText>회원가입 후 코넛스퀘어의 다양한 서비스를 즐겨보세요.</LoginInfoText>
                        <JoginButton onClick={() => moveLink('join', '/join')}>회원가입</JoginButton>
                    </>
                )}
                <SwapBox onClick={() => moveLink('swap', '/swap')}>
                    <Swap>코넛스왑</Swap>
                </SwapBox>
                <CategoryTitle>카테고리</CategoryTitle>
                <HambugerMenuList>
                    <MenuList
                        type="premium"
                        onClick={() => moveLink('premium', '/premium-market')}
                    >
                        핫한 크리에이터
                    </MenuList>
                    <MenuList
                        type="marketplace"
                        onClick={() => moveLink('marekt', '/marketplace')}
                    >
                        마켓플레이스
                    </MenuList>
                    <MenuList
                        type="creative_challenge"
                        onClick={() => moveLink('marekt', '/creative-challenge')}
                    >
                        크리에이티브
                        <br />
                        챌린지
                    </MenuList>
                    <MenuList
                        type="notice"
                        onClick={() => moveLink('out', 'https://conuts.zendesk.com/hc/ko/categories/18111212206361-%EA%B3%B5%EC%A7%80%EC%82%AC%ED%95%AD')}
                    >
                        공지사항
                    </MenuList>
                    <MenuList
                        type="inquiry"
                        onClick={() => moveLink('out', 'https://conuts.zendesk.com/hc/ko/requests/new')}
                    >
                        1:1문의
                    </MenuList>
                </HambugerMenuList>
                {userAddress.address !== '' && (
                    <LogoutBox onClick={handleLogout}>
                        <LogoutText>로그아웃</LogoutText>
                    </LogoutBox>
                )}
            </HambugerBox>
        </HambugerContainer>

    );
}

const HambugerBox = styled.div`
    width: 100%;
    height: 100%;
    padding: 40px 20px;
    background-color: ${colors.White100};
    display: flex;
    flex-direction: column;
`;

const HambugerTitle = styled.div`
    font-size: 24px;
    color: ${colors.Black100};
    font-weight: 700;
`;

const HambugerContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    backdrop-filter: blur(16px);
    background-color: ${rgba(colors.Black200, 0.85)};
    z-index: 999999999;
    padding-left: 20px;
    overflow-x: auto;
`;

const CategoryTitle = styled.div`
    font-size: 16px;
    font-weight: 700;
`;

const HambugerMenuList = styled.div`
    margin-top: 24px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;

const MenuList = styled.div<{type: string}>`
    flex-basis: 33.33%;
    height: 75px;
    font-size: 13px;
    font-weight: 300;
    color: ${colors.Black100};
    margin-bottom: 24px;
    text-align: center;
    display: flex;
    flex-direction: column;

    &::before {
        width: 40px;
        height: 40px;
        content: url('/img/common/ic_${(props) => (props.type)}.svg');
        display: block;
        margin: 0 auto;
        margin-bottom: 8px;
    }
`;

const CloseBtn = styled('img')`
    width: 24px;
    cursor: pointer;
`;

const HambugerClose = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const UserProfile = styled.div`
    margin-top: 14px;
    padding-bottom: 25px;
    display: flex;
    align-items: center;
`;

const ProfileImage = styled('img')`
    width: 48px;
    height: 48px;
    object-fit: cover;
`;

const ProfileImageBox = styled.div`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
`;

const TitleSection = styled.div`
    color: ${colors.Black100};
    display: flex;
    flex-direction: column;
    font-size: 13px;

    span {
        color: ${colors.BlueGray700};
        font-size: 13px;
        font-weight: 400;
        font-family: ${fonts.Tinos};
        margin-bottom: 2px;
    }
`;

const WalletAdddress = styled.div``;

const AddressTitle = styled.div`
    font-size: 14px;
    font-weight: 500;
`;

const AdddressBox = styled.div`
    width: 100%;
    margin-top: 15px;
    color: ${colors.Black100};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid ${rgba(colors.Black100, 0.1)};
`;

const Address = styled.div`
    flex-basis: calc(100% - 30px);
    line-clamp: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;  /* 말줄임 적용 */
    height: 16px;
`;

const CoinIcon = styled('img')`
    width: 25px;
`;

const Copy = styled('img')`
    width: 20px;
    cursor: pointer;
`;

const InfoText = styled.div`
    margin-top: 8px;
    color: ${colors.Black100};
    font-size: 13px;
    font-weight: 300;
`;

const BalancePrice = styled.div`
    font-size: 16px;
    color: ${colors.Black100};
    font-weight: 500;

    span {
        font-size: 13px;
        color: ${colors.Black100};
        font-weight: 300;
        margin-left: 5px;
    }
`;

const LogoutBox = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
`;

const LogoutText = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: ${rgba(colors.Black100, 0.3)};
`;

const LogInText = styled.div`
    font-size: 14px;
    font-weight: 400;
    color: ${colors.Black100};
    display: flex;
    align-items: center;

    &::before {
        width: 24px;
        height: 24px;
        content: url('/img/common/ic_login.svg');
        margin-right: 8px;
    }
`;

const LoginBox = styled.div`
    width: 100%;
    height: 70px;
    margin-top: 40px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;;
    background-color: ${colors.White100};
    border: 1px solid ${rgba(colors.Black100, 0.2)};
    border-radius: 8px; 
    cursor: pointer;

    &::after {
        width: 7px;
        height: 14px;
        content: url('/img/common/ic_login_arrow.svg');
    }
`;

const LoginInfoText = styled.div`
    margin-top: 12px;
    font-size: 13px;
    color: ${rgba(colors.Black100, 0.7)};
    text-align: center;
`;

const JoginButton = styled.div`
    font-size: 14px;
    font-weight: 400;
    color: ${colors.Black100};
    margin-top: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 36px;

    &::before {
        width: 24px;
        height: 24px;
        content: url('/img/common/ic_join.svg');
        margin-right: 8px;
    }
`;

const KlayBalanceBox = styled.div`
    margin-top: 12px;
    margin-bottom: 12px;
`;

const BalanceBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 54px;
    padding: 0 10px;
    background-color: ${colors.BlueGray300};
    border-radius: 6px;
`;

const SwapBox = styled.div`
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid ${rgba(colors.Black100, 0.1)};
`;

const Swap = styled.div`
    background-image: url('/img/swap/ic_swap_button.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    width: 100%;
    height: 56px;
    color: ${colors.Black200};
    font-size: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;

    &::before {
        display: block;
        content:url('/img/swap/ic_conut_swap.svg');
        width: 24px;
        height: 24px;
        margin-right: 8px;
    }
`;

export default Hambuger;
