import React, { useState } from 'react';
import styled from '@emotion/styled/macro';
import { colors } from '@styles/ui_palette';
import { rgba } from 'emotion-rgba';
import { useRecoilValue } from 'recoil';
import { IAssetsDetail } from '@interface/assets';

// recoil
import { UserAddress } from '@recoil/auth';

interface AssetsDetail {
    nftAssets: IAssetsDetail;
    handleMarketCancel: (nft: IAssetsDetail) => void;
}

function BookmarkPanel({ nftAssets, handleMarketCancel }: AssetsDetail) {
    const [isDrop, setDrop] = useState<boolean>(false);
    const userAddress = useRecoilValue(UserAddress);

    const handleToggle = () => {
        setDrop(!isDrop);
    };

    return (
        <StickyBox>
            <SingleContainer>
                <StatusLabelBox>
                    준비중
                </StatusLabelBox>
                <StatusText>
                    서비스 준비중입니다.
                </StatusText>
                {/* <BookmarkeImage src="/img/creative_challenge/img_bookmark.png" />

                <BookmarkeText>
                    이용에 불편을 드려 죄송합니다.
                    <br />
                    빠른 시일내에 준비하여 찾아뵙겠습니다.
                </BookmarkeText> */}

                {userAddress.address === nftAssets.owner_address
                && (
                    <MenuBox>
                        <MenuIcon
                            onClick={handleToggle}
                            src="/img/creative_challenge/ic_menu.svg"
                        />

                        {isDrop && (
                            <MenuDropBox>
                                {/* <DropMenu>수정하기</DropMenu> */}
                                <DropMenu onClick={() => handleMarketCancel(nftAssets)}>삭제하기</DropMenu>
                            </MenuDropBox>
                        )}

                    </MenuBox>
                )}

            </SingleContainer>
        </StickyBox>
    );
}

const StickyBox = styled.div`
    width: 100%;
    /* margin-top: 45px; */
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 999;
    background-color: ${colors.White100};
    border-top: 1px solid ${colors.BlueGray400};
    padding: 20px 0;
`;

const SingleContainer = styled.div`
    text-align: center;
`;

const StatusLabelBox = styled.div`
    width: 60px;
    height: 30px;
    background-color: ${rgba(colors.Green100, 0.1)};
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    color: ${colors.Green100};
    font-size: 13px;
`;

const StatusText = styled.div`
    color: ${colors.Black100};
    font-weight: 500;
    margin-top: 16px;
`;

// const BookmarkeImage = styled('img')`
//     width: 156px;
//     margin-top: 20px;
// `;

// const BookmarkeText = styled.div`
//     margin-top: 8px;
//     font-size: 13px;
//     font-weight: 300;
//     color: ${colors.Black100};
// `;

const MenuBox = styled.div`
    width: 74px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
`;

const MenuIcon = styled('img')`
    cursor: pointer;
`;

const MenuDropBox = styled.div`
    width: 74px;
    border-radius: 8px;
    border: 1px solid ${colors.BlueGray400};
    padding: 12px 0;

    &:before {
        width: 14px;
        height: 8px;
        display: block;
        content: url('/img/creative_challenge/ic_drop_arrow.svg');
        position: absolute;
        top: 11px;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 10;
    }
`;

const DropMenu = styled.div`
    font-size: 13px;
    color: ${colors.Black100};
    cursor: pointer;

    &:first-of-type {
        /* margin-bottom: 8px; */
    }

    &:hover {
        text-decoration: underline;
    }
`;

export default BookmarkPanel;
