import React, { useState, useMemo } from 'react';
import styled from '@emotion/styled/macro';
import { useNavigate, useLocation } from 'react-router-dom';
import { transparentHeader } from '@utils/help';
import { colors } from '@styles/ui_palette';
import { rgba } from 'emotion-rgba';
import { useRecoilValue } from 'recoil';

import useScroll from '@components/common/utils/useScroll';

// recoil
import { UserAddress } from '@recoil/auth';
import Hambuger from '@components/common/Hambuger';

function Header() {
    const navigate = useNavigate();
    const { y } = useScroll();
    const [isMenu, setIsMenu] = useState<boolean>(false);
    const { pathname } = useLocation();
    const userAddress = useRecoilValue(UserAddress);

    const getHeaderType = useMemo(() => {
        if (transparentHeader(pathname) && y < 200) {
            return 'transparent';
        }

        if (transparentHeader(pathname) && y < 200) {
            return colors.White100;
        }

        return rgba(colors.White100, 0.75);
    }, [pathname, y]);

    return (
        <>
            <Headers
                yScroll={y}
                type={getHeaderType}
                isMain={transparentHeader(pathname)}
            >
                <Logo>
                    <LogoImage
                        src="/img/layout/header/ic_logo.svg"
                        onClick={() => navigate('/main')}
                    />
                </Logo>
                <HeaderMenu>
                    <Mypage
                        src="/img/layout/header/ic_mypage_black.svg"
                        onClick={() => navigate(userAddress.address === '' ? '/main' : `/mypage/${userAddress.address}`)}
                    />
                    <HambugerImage
                        src="/img/layout/header/ic_hamburger_black.svg"
                        onClick={() => setIsMenu(true)}
                    />
                </HeaderMenu>
            </Headers>
            {isMenu && (
                <Hambuger setIsMenu={setIsMenu} />
            )}

        </>
    );
}

const Headers = styled.header<{type: string, isMain: boolean, yScroll: number}>`
    width: 100%;
    height: 60px;
    position: fixed;
    backdrop-filter: ${(props) => (props.type === 'transparent' ? 'blur(0)' : 'blur(16px)')};
    background-color: ${(props) => (props.type)};
    box-shadow: ${(props) => (props.isMain && props.yScroll < 200 ? '0' : `0px 4px 5px 0px ${rgba(colors.Black100, 0.05)}`)};
    ${(props) => (props.isMain && props.yScroll < 200 ? '0' : `1px solid ${rgba(colors.White100, 0.2)}`)};
    left: 0;
    top: 0;
    z-index: 1000;
    transition: all .1s;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const HeaderMenu = styled.div`
    flex-basis: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Logo = styled.div`
    display: flex;
    align-items: center;
`;

const LogoImage = styled('img')`
    cursor: pointer;
    width: 114px;
`;

const HambugerImage = styled('img')`
    cursor: pointer;
    width: 24px;
`;

const Mypage = styled('img')`
    cursor: pointer;
    width: 40px;
`;

export default Header;
