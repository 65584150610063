import React from 'react';
import styled from '@emotion/styled/macro';
import { fonts, common, colors } from '@styles/ui_palette';
import { useTranslation } from 'react-i18next';
import { nl2br } from '@utils/help';

function AboutStep1() {
    const { t } = useTranslation();

    return (
        <AboutMainVisual>
            <Container>
                <Title>
                    {t('About.aboutSectionMainTitle')}
                </Title>
                <Description>
                    {t('About.aboutSectionMainDesc')}
                </Description>
            </Container>

            <AboutTitleBox>
                <Step>1</Step>
                <MainTitle>
                    쉽고 간편하게 카카오 클립(Klip)
                    <br />
                    지갑으로 로그인 후 토스 결제
                </MainTitle>
                <SubTitle>
                    <SubtitleActiveText dangerouslySetInnerHTML={{ __html: nl2br(t('About.aboutSectionContents_01')) }} />
                    <SubtitleText dangerouslySetInnerHTML={{ __html: nl2br(t('About.aboutSectionContents_02')) }} />
                </SubTitle>
            </AboutTitleBox>
            <AboutStroyContents src="/img/about/img_home_story.png" />
        </AboutMainVisual>
    );
}

const AboutMainVisual = styled.section`
    background-color: ${colors.White100};
    background-repeat: no-repeat;
    width: 100%;
    padding: 44px 20px ;
`;

const Container = styled.div`
    width: ${common.contents};
    margin: 0 auto;
    padding: 0 20px;
`;

const Title = styled.div`
    font-size: 24px;
    font-weight: 500;
    color: ${colors.Black100};
    font-family: ${fonts.Tinos};
    text-align: center;
`;

const Description = styled.div`
    font-size: 13px;
    color: ${colors.Black100};
    text-align: center;
    margin-top: 12px;
    text-align: center;
`;

const AboutTitleBox = styled.div`
    text-align: center;
`;

const MainTitle = styled.div`
    font-size: 24px;
    color: ${colors.Black100};
    margin-top: 14px;
    font-weight: 500;
    text-align: center;
`;

const SubTitle = styled.div`
    margin-top: 12px;
    font-size: 14px;
    color: ${colors.Black100};
    line-height: 1.7;
    word-break: keep-all;
`;

const Step = styled.div`
    font-size: 34px;
    color: ${colors.Black100};
    font-family: ${fonts.Tinos};
    position: relative;
    display: inline-block;
    margin-top: 44px;

    &:before {
        width: 31px;
        height: 1px;
        display: block;
        background: ${colors.Orange100};
        content: '';
        transform: rotate(-45deg);
        position: absolute;
        bottom: 6px;
        left: 5px;
    }

`;

const AboutStroyContents = styled('img')`
    width: 100%;
    margin-top: 34px;
`;

const SubtitleActiveText = styled.span`
    color: ${colors.Orange100};
`;

const SubtitleText = styled.span``;

export default AboutStep1;
