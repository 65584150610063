import React from 'react';
import styled from '@emotion/styled/macro';
import { colors, fonts } from '@styles/ui_palette';
import { useTranslation } from 'react-i18next';
import { nl2br } from '@utils/help';

function AboutStep2() {
    const { t } = useTranslation();

    return (
        <SctionBg>
            <AboutTitleBox>
                <Step>2</Step>
                <MainTitle>
                    {t('About.aboutSectionTitle_02')}
                </MainTitle>
                <SubTitle>
                    <SubtitleActiveText>{t('About.aboutSectionContents_03')}</SubtitleActiveText>
                    <SubtitleText dangerouslySetInnerHTML={{ __html: nl2br(t('About.aboutSectionContents_04')) }} />
                </SubTitle>
                <AboutStroyContents src="/img/about/img_home_story2.png" />
            </AboutTitleBox>
        </SctionBg>
    );
}

const SctionBg = styled.div`
    background-color: ${colors.BlueGray400};
    padding: 44px 20px;
`;

const AboutTitleBox = styled.div`
    text-align: center;
`;

const MainTitle = styled.div`
    font-size: 24px;
    font-weight: 500;
    color: ${colors.Black100};
    margin-top: 12px;
    font-weight: 500;
`;

const SubTitle = styled.div`
    margin-top: 12px;
    font-size: 13px;
    color: ${colors.Black100};
    line-height: 1.7;
`;

const Step = styled.div`
    font-size: 34px;
    color: ${colors.Black100};
    font-family: ${fonts.Tinos};
    position: relative;
    display: inline-block;

    &:before {
        width: 31px;
        height: 1px;
        display: block;
        background: ${colors.Orange100};
        content: '';
        transform: rotate(-45deg);
        position: absolute;
        bottom: 6px;
        left: 5px;
    }

`;

const AboutStroyContents = styled('img')`
    width: 100%;
    margin-top: 34px;
`;

const SubtitleActiveText = styled.span`
    color: ${colors.Orange100};
`;

const SubtitleText = styled.span``;

export default AboutStep2;
