import styled from '@emotion/styled/macro';
import { fonts, colors } from '@styles/ui_palette';
import { useTranslation } from 'react-i18next';

// components
import AboutMainVisualImage from '@components/about/AboutMainVisualImage';

function AboutMainVisual() {
    const { t } = useTranslation();

    return (
        <AboutMainVisualSection>
            <Container>
                {/* 메인 타이틀 */}
                <AboutTitleBox>
                    <MainTitle>
                        {t('About.title')}
                        <TitleSpan>{t('About.subTitle')}</TitleSpan>
                    </MainTitle>
                    <SubTitle>
                        코넛스퀘어는 즐거움 과 참여라는 새로운 가치로 꾸며진 실물과
                        <br />
                        디지털의 퓨전마켓플레이스 입니다
                    </SubTitle>
                </AboutTitleBox>

                {/* 메인 이미지 */}
                <AboutMainVisualImage />
            </Container>
        </AboutMainVisualSection>
    );
}

const AboutMainVisualSection = styled.section`
    background-color: ${colors.Black200};
    width: 100%;
`;

const Container = styled.div`
    width: 100%;
    padding: 0 20px;
`;

const AboutTitleBox = styled.div`
    padding-top: 24px;
`;

const MainTitle = styled.div`
    font-size: 32px;
    color: ${colors.White100};
    font-family: ${fonts.Tinos};
    line-height: 1.2;
    text-align: center;
`;

const TitleSpan = styled.div`
    font-family: ${fonts.Tinos};
    color: ${colors.Orange100};
`;

const SubTitle = styled.div`
    margin-top: 16px;
    font-size: 13px;
    font-weight: 400;
    color: ${colors.White100};
    line-height: 1.7;
    text-align: center;
`;

export default AboutMainVisual;
