import React from 'react';
import styled from '@emotion/styled/macro';
import { colors } from '@styles/ui_palette';
import 'keen-slider/keen-slider.min.css';
import { nl2br } from '@utils/help';
import { useTranslation } from 'react-i18next';

const SlideArr = () => [
    {
        id: 3,
        title: '플랫폼 서비스 확장\n멀티체인 지원',
        years: '2023',
        items: [
            '카카오 클립(Klip) 간편 로그인 개발',
            '토스(Toss) 간편 결제 개발',
            '클레이튼(KLAY)네트워크 지원',
            '이더리움(ETH)네트워크 지원',
            '커뮤니티 활성화',
        ],
    },
    {
        id: 4,
        title: '서비스 고도화 및 안정화',
        years: '2023',
        items: [
            '카카오 클립(Klip) 간편 로그인 지원',
            '토스(Toss) 간편 결제 지원',
            '코넛스퀘어 NFT 상품 판매',
            'NFT 크라우드펀딩 서비스 오픈',
            '플랫폼 제휴 확대',
        ],
    },
    {
        id: 1,
        title: '플랫폼 서비스 확장\n멀티체인 지원',
        years: '2024',
        items: [
            'NFT 크라우드펀딩 서비스 개발',
            '부동산 NFT 서비스 개발',
            'P2E NFT',
            '폴리곤(MATIC)네트워크 지원',
            '추가 거래 기능 활성화',
            '코넛스퀘어 모바일 앱 개발',
        ],
    },
    {
        id: 2,
        title: '모바일 앱 출시\n서비스 고도화 및 안정화',
        years: '2024',
        items: [
            '코넛스퀘어 모바일 앱 출시',
            '솔라나(SOL)네트워크 지원',
            '사용자 중심의 거래 경험 고도화',
            'NFT 컬렉션 자동 인증 시스템 준비',
            'NFT 레퍼럴, NFT 친구선물하기',
            '신용카드 NFT 결제 지원',
        ],
    },
];

function AboutLoadmap() {
    const { t } = useTranslation();
    const slideCard = SlideArr();

    return (
        <SectionBackground>
            <Container>
                <TitleBox>
                    <Title>{t('About.aboutLoadMapTitle')}</Title>
                    <Description>
                        <DescriptionActiveText dangerouslySetInnerHTML={{ __html: nl2br(t('About.aboutLoadMapDescription_01')) }} />
                        <DescriptionText dangerouslySetInnerHTML={{ __html: nl2br(t('About.aboutLoadMapDescription_02')) }} />
                    </Description>
                </TitleBox>
                <LoadMapSection>
                    <CardScrollX>
                        {slideCard.map(((load: any) => (
                            <KeenSliderItem
                                key={load.id}
                                className="keen-slider__slide"
                            >
                                <LoadTitleBox>
                                    <LoadMapTitle>
                                        {load.id}
                                        {t('About.aboutLoadMapSection')}
                                    </LoadMapTitle>
                                    <LoadMapYears>
                                        {load.years}
                                        {t('About.aboutLoadMapYears')}
                                    </LoadMapYears>
                                </LoadTitleBox>
                                <LoadName dangerouslySetInnerHTML={{ __html: nl2br(load.title) }} />
                                {load.items.map((item: any) => (
                                    <LoadItems
                                        key={item}
                                    >
                                        -
                                        {' '}
                                        {item}
                                    </LoadItems>
                                ))}
                            </KeenSliderItem>
                        )))}
                    </CardScrollX>
                </LoadMapSection>
            </Container>
        </SectionBackground>
    );
}

const CardScrollX = styled.div`
    width: 1212px;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    overflow: hidden;
    padding: 24px 20px;
    padding-bottom: 0;
`;

const SectionBackground = styled.div`
    background-color: ${colors.BlueGray200};
    padding: 44px 0;
`;

const Container = styled.div``;

const TitleBox = styled.div``;

const Title = styled.div`
    font-size: 24px;
    font-weight: 500;
    color: ${colors.Black100};
    text-align: center;
`;

const Description = styled.div`
    margin-top: 12px;
    font-size: 13px;
    color: ${colors.Black100};
    line-height: 1.5;
    text-align: center;
`;

const DescriptionText = styled.span``;

const DescriptionActiveText = styled.span`
    color: ${colors.Orange100};
`;

const LoadMapSection = styled.div`
    width: 100%;
    overflow-x: auto;
`;

const KeenSliderItem = styled.div`
    width: 284px;
    flex-basis: 284px;
    flex-shrink: 0;
    min-height: 400px;
    background-color: ${colors.White100};
    border: 2px solid ${colors.Orange100};
    border-radius: 8px;
    margin-right: 12px;
    padding: 44px 20px;
    padding-bottom: 0;
    word-break: keep-all;
    white-space: break-spaces;
`;

const LoadTitleBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const LoadMapTitle = styled.div`
    font-size: 13px;
    color: ${colors.Black100};
`;

const LoadMapYears = styled.div`
    font-size: 14px;
    color: ${colors.Black100};
`;

const LoadName = styled.div`
    margin-top: 24px;
    font-size: 20px;
    color: ${colors.Black100};
    font-weight: 500;
    padding-bottom: 24px;
`;

const LoadItems = styled.div`
    margin: 10px 0;
    font-size: 14px;
    color: ${colors.Black100};
`;

export default AboutLoadmap;
