import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled/macro';
// import { colors } from '@styles/ui_palette';

// components
import MainMainVisual from '@components/main/MainMainVisual';
import MainPremiumMarket from '@components/main/MainPremiumMarket';
import MainMarketPlace from '@components/main/MainMarketPlace';
import MainCreativeChallenge from '@components/main/MainCreativeChallenge';
import MainBannerSection from '@components/main/MainBannerSection';
import MainCategory from '@components/main/MainCategory';
// import MainInformation from '@components/main/MainInformation';
import MainNoticeModals from '@components/main/MainNoticeModals';

function Home() {
    const [isNoticeModal, setNoticeModal] = useState<boolean>(false);
    const modalExpire = Date.now() + (21600000 * 4);

    const handleNoticeModal = (action: string) => {
        if (action === 'day') {
            localStorage.setItem('modalExpire', modalExpire.toString());
            setNoticeModal(false);
            return;
        }

        setNoticeModal(false);
    };

    useEffect(() => {
        const getModalExpire = localStorage.getItem('modalExpire');

        if (getModalExpire && Number(getModalExpire) > Date.now()) {
            setNoticeModal(false);
        } else {
            setNoticeModal(true);
        }
    }, []);

    return (
        <>
            {/* 메인 비쥬얼 */}
            <MainMainVisual />

            {/* 프리미엄 마켓 */}
            <MainPremiumMarket />

            <SectionBackground>
                {/* 마켓 플레이스 */}
                <MainMarketPlace />

                {/* 크리에이티브 챌린지 */}
                <MainCreativeChallenge />

                {/* 베너영역 */}
                <MainBannerSection />

                {/* 카테고리 */}
                <MainCategory />

                {/* 사용방법 */}
                {/* <HomeInformation /> */}

                {/* 크리에이터 지원 베너 */}
                {/* <HomePartnerBanner>
                    <PartnerContain>

                        <PartnerTitle>크리에이터, 파트너업체 신청</PartnerTitle>
                        <PartnerDesc>
                            창작콘텐츠를 기반으로 하는 실물상품을 블록체인과 결합해 판매하고자 하는 크리에이터 또는 업체라면, 누구든 신청할 수 있습니다.
                        </PartnerDesc>

                        <PartnerButton onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLSemyysTxspH4IsErmzwWtYyUxJEN3a5yMmPe-rxZoSBd7kK7A/viewform?usp=send_form')}>
                            지원하기
                        </PartnerButton>
                    </PartnerContain>
                </HomePartnerBanner> */}

            </SectionBackground>

            {isNoticeModal && <MainNoticeModals handleNoticeModal={handleNoticeModal} />}
        </>
    );
}

const SectionBackground = styled.div``;

// const HomePartnerBanner = styled.div`
//     background-color: ${colors.Black100};
// `;

// const PartnerContain = styled.div`
//     width: 100%;
//     margin: 0 auto;
//     padding: 34px 16px;
// `;

// const PartnerTitle = styled.div`
//     font-size: 20px;
//     font-weight: 700;
//     color: ${colors.White100};
// `;

// const PartnerDesc = styled.div`
//     font-size: 13px;
//     font-weight: 300;
//     color: ${colors.White100};
//     margin-top: 8px;
// `;

// const PartnerButton = styled.div`
//     width: 200px;
//     height: 58px;
//     font-size: 14px;
//     font-weight: 400;
//     color: ${colors.White100};
//     border-radius: 999px;
//     border: 1px solid ${colors.White100};
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     cursor: pointer;
//     margin-top: 12px;
// `;

export default Home;
