import React from 'react';
import styled from '@emotion/styled/macro';

// components
import HomeMain from '@components/home/HomeMain';
import HomeMainSlideBanner from '@components/home/HomeMainSlideBanner';
import HomeMarketList from '@components/home/HomeMarketList';

function Home() {
    return (
        <Container>

            <HomeMain />

            <HomeMainSlideBanner />

            <HomeMarketList />

        </Container>
    );
}

const Container = styled.div`
    width: 100%;
    margin: 0 auto;
    padding: 0 16px;
    padding-top: 32px;
    padding-bottom: 60px;
`;

export default Home;
