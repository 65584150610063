import React from 'react';
import styled from '@emotion/styled/macro';

function Swap() {
    return (
        <Container>
            <SwapImage src="/img/swap/img_swap_contents.png" />
        </Container>
    );
}

const Container = styled.div`
    width: 100%;
    padding: 30px 0 70px 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SwapImage = styled('img')`
    width: 100%;
`;

export default Swap;
