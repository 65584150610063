import React from 'react';
import styled from '@emotion/styled/macro';
import { TypeAnimation } from 'react-type-animation';

function HomeMain() {
    return (
        <Contain>
            <TitleBox>
                <MainTitle src="/img/home/img_main_title.svg" />
            </TitleBox>
            <TypeAnimation
                sequence={[
                    '코넛스퀘어...',
                    3000,
                    'WEB3 인큐베이팅 플랫폼 ',
                    3000, // Waits 2s
                    'Mixed Reality NFT 마켓플레이스',
                    3000, // Waits 2s
                ]}
                wrapper="span"
                cursor
                speed={20}
                repeat={Infinity}
                style={{ fontSize: '16px', fontWeight: '700', display: 'inline-block' }}
            />
        </Contain>
    );
}

const Contain = styled.div`
    width: 100%;
`;

const TitleBox = styled.div`
    margin-bottom: 8px;
`;

const MainTitle = styled('img')`
    width: 200px;
`;

export default HomeMain;
