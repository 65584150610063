import React from 'react';
import styled from '@emotion/styled/macro';
import { colors } from '@styles/ui_palette';
import { nl2br } from '@utils/help';
import { useTranslation } from 'react-i18next';

const CreateInfo = (t:any) => [
    {
        id: 1,
        imgae: '/img/main/img_step_1.png',
        name: t('About.aboutNftInfoSectionItemTitle_01'),
        contents: t('About.aboutNftInfoSectionItem_01'),
        button: true,
        connect: true,
    },
    {
        id: 2,
        imgae: '/img/main/img_step_2.png',
        name: t('About.aboutNftInfoSectionItemTitle_02'),
        contents: t('About.aboutNftInfoSectionItem_02'),
        button: true,
        connect: false,
    },
    {
        id: 3,
        imgae: '/img/main/img_step_3.png',
        name: t('About.aboutNftInfoSectionItemTitle_03'),
        contents: t('About.aboutNftInfoSectionItem_03'),
        button: true,
        connect: false,
    },
];

function AboutInformation() {
    const { t } = useTranslation();
    const createInfo = CreateInfo(t);

    return (
        <SectionBackground>
            <Container>
                <Title>{t('About.aboutNftInfoSectionTitle')}</Title>
                <SubTitle>{t('About.aboutNftInfoSectionDesc')}</SubTitle>
                <InfoWrap>
                    {createInfo.map((info: any) => (
                        <InfoItem
                            key={info.id}
                        >
                            <InfoContents>
                                <InfoIcon src={info.imgae} />
                                <Step>
                                    0
                                    {info.id}
                                </Step>
                                <Name>{info.name}</Name>
                                <Contents dangerouslySetInnerHTML={{ __html: nl2br(info.contents) }} />
                            </InfoContents>
                        </InfoItem>
                    ))}

                </InfoWrap>
            </Container>
        </SectionBackground>
    );
}

const SectionBackground = styled.div`
    background-color: ${colors.Black100};
`;

const Container = styled.div`
    padding: 44px 20px;
    text-align: center;
`;

const Title = styled.div`
    font-size: 24px;
    font-weight: 500;
    color: ${colors.White100};
    text-align: center;
`;

const SubTitle = styled.div`
    margin-top: 14px;
    color: ${colors.White100};
    font-size: 14px;
    text-align: center;
`;

const InfoWrap = styled.div`
    margin-top: 24px;
`;

const InfoItem = styled.div`
    width: 100%;
    margin-bottom: 24px;

    &:last-of-type {
        margin-bottom: 0;
    }
`;

const InfoContents = styled.div`
    background-color: ${colors.Black200};
    min-height: 280px;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 0 35px;
    border-radius: 8px;
`;

const InfoIcon = styled('img')`
    width: 80px;
    min-height: 80px;
    margin: 0 auto;
    margin-top: 32px;
`;

const Step = styled.div`
    margin-top: 12px;
    font-size: 14px;
    color: ${colors.Orange100};
`;

const Name = styled.div`
    margin-top: 8px;
    color: ${colors.White100};
    font-size: 18px;
`;

const Contents = styled.div`
    margin-top: 12px;
    color: ${colors.BlueGray600};
    line-height: 1.5;
    font-size: 13px;
    word-break: keep-all;
`;

export default AboutInformation;
